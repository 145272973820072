<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/home/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home/signList/signList?tid=' + tid }"
        >签到活动</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: '/home/signList/signlinkList/signlinkList?actId=' + actId
        }"
        >签到环节</el-breadcrumb-item
      >
      <el-breadcrumb-item>签到结果</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="result-container" style="margin:10px 20px">
      <!-- <div class="total-layout">

      </div> -->
      <el-card
        class="operate-container"
        style="height: 100%;border:none"
        shadow="never"
      >
        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :span="18">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="total-frame">
                  <img :src="img_home_order" class="total-icon" />
                  <div class="total-title">课程总人数</div>
                  <div class="total-value">{{ totalnum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="total-frame">
                  <img :src="img_home_today_amount" class="total-icon" />
                  <div class="total-title">已签到人数</div>
                  <div class="total-value">{{ signnum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="total-frame">
                  <img :src="img_home_yesterday_amount" class="total-icon" />
                  <div class="total-title">未签到人数</div>
                  <div class="total-value">{{ unsignnum }}</div>
                </div>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="6" style="text-align: center">
            <el-button
              :loading="downloadLoading"
              style="margin-top: 20px; margin-bottom: 20px; height: 45px"
              type="primary"
              icon="el-icon-document"
              @click="clickDialog"
            >
              选择名单
            </el-button>
            <el-button
              :loading="downloadLoading"
              style="margin-top: 20px; margin-bottom: 20px; height: 45px"
              type="primary"
              icon="el-icon-document"
              @click="exportExcel"
            >
              导出数据
            </el-button>
          </el-col>
        </el-row>
        <el-dialog
          title="选择名单"
          :visible.sync="dialogTableVisible"
          width="800px"
        >
          <a
            @click="downloadxls()"
            style="color: #419eff; border-bottom: 0.5px dotted #419eff"
            >下载模板</a
          >
          <div style="text-align: center">
            <el-upload
              class="upload-demo"
              drag
              action="https://www.xqzjgsu.top:8001/class/uploadClassList"
              :on-change="handleChange"
              :headers="headers"
              :data="listData"
              name="list"
              ref="upload"
              accept=".xls,.xlsx"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将xls/xlsx文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div>
          <div>
            <el-table
              :data="listHistory"
              height="400"
              style="margin-top: 0 !important"
            >
              <el-table-column prop="fileName" label="历史名单">
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="handleCompare(scope.$index, scope.row)"
                    >对比</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-dialog>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-tabs
              type="border-card"
              style="height: 500px; overflow-y: auto; overflow-x: hidden;border-radius: 8px;"
            >
              <el-tab-pane
                label="已签到"
                style="height: 90%; overflow-y: auto; overflow-x: hidden"
              >
                <div
                  v-if="signlist.length == 0"
                  style="text-align: center; margin-top: 15px"
                >
                  这里空空如也~
                </div>
                <div
                  v-for="item in signlist"
                  :key="item.name"
                  class="signlistclass"
                  v-else
                >
                  <div>
                    <el-avatar :src="item.avatar"></el-avatar>
                    <span class="imgrightspan">{{ item.name }}</span>
                  </div>
                  <div>
                    <span
                      v-if="item.state == '未按时签到' || item.state == '迟到'"
                      class="timeclass"
                      style="color: #ffd45f"
                    >
                      迟到
                    </span>
                    <span
                      v-if="item.state == '请假' || item.state == '其他'"
                      class="timeclass"
                      style="color: #4ab848"
                      >{{ item.state }}</span
                    >
                    <span
                      v-if="item.state == '旷课'"
                      class="timeclass"
                      style="color: #ed1c24"
                      >{{ item.state }}</span
                    >
                  </div>
                  <div>
                    <span class="timeclass">{{ item.signintime }}</span>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="未签到"
                style="height: 90%; overflow-y: auto; overflow-x: hidden"
              >
                <div
                  v-if="unsignlist.length == 0"
                  style="text-align: center; margin-top: 15px"
                >
                  这里空空如也~
                </div>
                <div
                  v-for="item in unsignlist"
                  :key="item.name"
                  class="signlistclass"
                  v-else
                >
                  <div>
                    <el-avatar :src="item.avatar"></el-avatar>
                    <span class="imgrightspan">{{ item.name }}</span>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="14" style="height: 500px"
            ><div
              class="statistics-layout"
              style="height: 500px;border-radius: 8px;"
            >
              <div class="layout-title">签到统计</div>
              <div style="padding: 5px">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        课程总人数
                      </div>
                      <div
                        style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        "
                      >
                        {{ totalnum }}
                      </div>
                    </div></el-col
                  >
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        已签到人数
                      </div>
                      <div
                        style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        "
                      >
                        {{ signnum }}
                      </div>
                    </div></el-col
                  >
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        未签到人数
                      </div>
                      <div
                        style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        "
                      >
                        {{ unsignnum }}
                      </div>
                    </div></el-col
                  >
                </el-row>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="chart-wrapper">
                    <bar-chart v-if="flag" :barData="histogramData" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="chart-wrapper">
                    <pie-chart :pieData="pieData" />
                  </div>
                </el-col>
              </el-row></div
          ></el-col>
        </el-row>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
import PieChart from './components/PieChart'
import BarChart from './components/BarChart'
import FilenameOption from './components/FilenameOption'
import AutoWidthOption from './components/AutoWidthOption'
import BookTypeOption from './components/BookTypeOption'
import UploadExcelComponent from '@/components/UploadExcel/index.vue'
import XLSX from 'xlsx'

import { str2Date } from '@/utils/date'
import img_home_order from '@/assets/images/home_order.png'
import img_home_today_amount from '@/assets/signfill.png'
import img_home_yesterday_amount from '@/assets/unsignfill.png'
const DATA_FROM_BACKEND = {
  columns: ['date', 'orderCount', 'orderAmount']
}
export default {
  props: {
    // beforeUpload: Function, // eslint-disable-line
    onSuccess: Function // eslint-disable-line
  },
  name: 'home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UploadExcelComponent,
    // eslint-disable-next-line vue/no-unused-components
    FilenameOption,
    // eslint-disable-next-line vue/no-unused-components
    AutoWidthOption,
    // eslint-disable-next-line vue/no-unused-components
    BookTypeOption,
    PieChart,
    BarChart
  },
  data() {
    this.histogramSettings = {
      yAxisType: 'normal',
      min: 0,
      digit: 0
    }
    this.pieSettings = {
      roseType: 'radius'
      // radius: [15, 95],
      // center: ['50%', '38%'],
    }
    return {
      flagg: false,
      histogramData: {
        xdata: ['已签到', '未签到'],
        arrdata: [0, 0]
      },

      pieData: {
        xdata: ['已签到', '未签到'],
        arrdata: [
          { value: 0, name: '已签到' },
          { value: 0, name: '未签到' }
        ]
      },

      excelData: {
        header: null,
        results: null
      },

      activeName: 'first',

      tableData: [],
      tableHeader: [],

      signnum: null,
      unsignnum: null,
      totalnum: 0,

      signlist: [],

      unsignlist: [],
      listLoading: false,
      multipleSelection: [],
      downloadLoading: false,
      filename: '',
      bookType: 'xlsx',
      autoWidth: true,

      sexList: [
        { label: '女', value: '0' },
        { label: '男', value: '1' }
      ],

      loading: false,
      dataEmpty: false,
      img_home_order,
      img_home_today_amount,
      img_home_yesterday_amount,

      flag: null,

      fileList: [],
      dialogTableVisible: false,
      listHistory: [],

      headers: {
        Authorization: 'Bearar ' + sessionStorage.getItem('Authorization')
      },

      listData: { uid: window.sessionStorage.getItem('tid') },
      myRes: []
    }
  },
  async created() {
    // 1-普通 2-管理员
    this.flag = sessionStorage.getItem('loginvalue')
    this.lid = this.$route.query.lid
    this.tid = window.sessionStorage.getItem('tid')
    this.actId = window.sessionStorage.getItem('actId')
    this.title = window.sessionStorage.getItem('title')
    await this.getSignList()
    this.getUnsignList()
    // this.fetchData()
  },
  methods: {
    //下载模板
    downloadxls() {
      window.location.href = process.env.BASE_URL + '名单模板.xls'
    },
    // 获取签到列表
    async getSignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        this.signlist = res.data[2]
        this.signnum = res.data[2].length
        this.totalnum += this.signnum
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        this.pieData.arrdata = [
          { value: this.signnum, name: '已签到' },
          { value: this.unsignnum, name: '未签到' }
        ]
        this.flag = true
      } else {
        this.signlist = []
        this.signnum = 0
        this.totalnum += this.signnum
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        this.pieData.arrdata = [
          { value: this.signnum, name: '已签到' },
          { value: this.unsignnum, name: '未签到' }
        ]
        this.flag = true
      }
    },
    // 获取未签到列表
    async getUnsignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher2', {
        params: {
          lid: this.lid
        }
      })
     // console.log('啥意思', res)
      if (res.code == 200) {
        this.unsignlist = res.data
        this.unsignnum = res.data.length
        this.totalnum += this.unsignnum
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        this.pieData.arrdata = [
          { value: this.signnum, name: '已签到' },
          { value: this.unsignnum, name: '未签到' }
        ]
        // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
        this.flag = true
        // console.log(this.pieData)
      } else {
        this.unsignlist = []
        this.unsignnum = 0
        this.totalnum += this.unsignnum
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        this.pieData.arrdata = [
          { value: this.signnum, name: '已签到' },
          { value: this.unsignnum, name: '未签到' }
        ]
        // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
        this.flag = true
        // console.log(this.pieData)
      }
    },
    // 导出文件
    exportExcel() {
      this.listLoading = true
      // window.location.href =
      //   'https://www.xqzjgsu.xyz/fss/rec/excelJYK?lid=' + this.lid

      // window.location.href = this.$http.post('rec/excel2', {
      //   params:{
      //     lid: this.lid,
      //   }
      // })

      this.$http({
        url: 'rec/excel',
        method: 'post',
        responseType: 'blob', // 表明返回服务器返回的数据类型
        data: {
          lid: this.lid,
          noSignIn: this.myRes
        }
      }).then(res => {
        // 处理返回的文件流
        const content = res.data

        const blob = new Blob([content])
        const fileName = '签到结果.xlsx'
        //console.log("nosignin",this.myRes)
        console.log('11111111111')
        console.log('res', this.myRes)
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },

    formatterSex({ cellValue }) {
      const item = this.sexList.find(item => item.value === cellValue)
      return item ? item.label : ''
    },

    // 获取历史名单
    async getHistoryList() {
      const { data: res } = await this.$http.get('class/classListHistory', {
        params: this.listData
      })
      this.listHistory = res.data
    },

    // 获取上传的文件
    async handleChange(file, fileList) {
      if (file.response != undefined) {
        if (file.response.code == 200) {
          const { data: res } = await this.$http.get('class/classListHistory', {
            params: this.listData
          })
          this.listHistory = res.data
        } else if (file.response.code != 200) {
          fileList = []
          this.$message({
            message: file.response.message,
            type: 'error',
            duration: 3000
          })
        }
      }
    },

    clickDialog() {
      this.dialogTableVisible = true
      this.getHistoryList()
    },

    // 上传文件前

    // 选中名单
    async handleCompare(index, row) {
      const { data: res } = await this.$http.get('rec/contrast', {
        params: {
          lid: this.lid,
          filePath: row.filePath
        }
      })

      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success',
          duration: 1000
        })
        console.log(res.data)
        this.myRes = res.data
        this.unsignlist = res.data
        this.unsignnum = res.data.length
        this.totalnum = this.signnum + this.unsignnum
        this.dialogTableVisible = false
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        this.pieData.arrdata = [
          { value: this.signnum, name: '已签到' },
          { value: this.unsignnum, name: '未签到' }
        ]
      } else {
        this.$message({
          message: res.message,
          type: 'error',
          duration: 1000
        })
      }
    },
    // 删除名单
    handleDelete(index, row) {
      this.$confirm('确认删除？')
        .then(async _ => {
          const { data: res } = await this.$http.get('class/deleteListFile', {
            params: {
              uid: window.sessionStorage.getItem('tid'),
              id: row.id,
              filePath: row.filePath
            }
          })
          if (res.code == 200) {
            this.$message({
              message: res.message,
              type: 'success',
              duration: 1000
            })
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 1000
            })
          }
          this.getHistoryList()
          done()
        })
        .catch(_ => {})
    },

    // 导出
    // 获取数据
    async fetchData() {
      this.listLoading = true
      const { data: files } = await this.$http.get(
        'rec/excel',
        {
          params: {
            lid: this.lid
          }
        },
        { responseType: 'blob' }
      )
    },

    readerData(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet)
          this.generateData({ header, results })
          this.loading = false
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },

    generateData({ header, results }) {
      this.excelData.header = header
      this.excelData.results = results
      this.onSuccess && this.onSuccess(this.excelData)
    },

    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleDownload() {
      if (this.multipleSelection.length) {
        this.downloadLoading = true
        import('@/vendor/Export2Excel').then(excel => {
          const tHeader = ['Id', 'Name', 'Role', 'Sex', 'Age', 'Address']
          const filterVal = ['id', 'name', 'role', 'sex', 'age', 'address']
          const list = this.multipleSelection
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename,
            autoWidth: this.autoWidth,
            bookType: this.bookType
          })
          this.$refs.multipleTable.clearSelection()
          this.downloadLoading = false
        })
      } else {
        this.$message({
          message: 'Please select at least one item',
          type: 'warning'
        })
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    handleSuccess({ results, header }) {
      this.tableData = results
      this.tableHeader = header
    },

    handleDateChange() {
      this.getData()
    }
  }
}
</script>

<style scoped>
/* 上传文件 */
.icon_style {
  margin: 0 0 7px !important;
}
.result-container {
  height: 100%;
  /* margin-top: 40px;
  margin-left: 120px;
  margin-right: 120px; */
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #dcdfe6;
  padding: 20px;
  height: 100px;
  border-radius: 8px;
}

.total-icon {
  color: #409eff;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -67px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -54px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #dcdfe6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #f2f6fc;
  font-weight: bold;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #ebeef5;
  padding: 10px;
}

.overview-layout {
  margin-top: 20px;
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #dcdfe6;
}

.statistics-layout {
  border: 1px solid #dcdfe6;
}
.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}
.address-content {
  padding: 20px;
  font-size: 18px;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

.chart-wrapper {
  background: #fff;
  padding: 16px 16px 0;
  margin-bottom: 32px;
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}

.signlistclass {
  display: flex;
  justify-content: space-between;
  padding: 10px 60px;
}

.imgrightspan {
  vertical-align: 50%;
  margin-left: 25px;
}

.timeclass {
  line-height: 45px;
}
</style>
